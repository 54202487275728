
import { defineComponent, ref } from 'vue';
import SbomDataService from '@/services/SbomDataService';
import ResponseData from '@/types/ResponseData';
import SbomEchart from '@/views/SbomDashboard/SbomEchart.vue';
import { ElMessage } from 'element-plus';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'SbomVulImpactChart',
  components: {
    SbomEchart,
  },
  props: {},
  data() {
    return {
      chartData: {},
      graphColors: [
        { label: '漏洞', prop: 'vulnerability', color: '#FF0000' },
        { label: '漏洞影响的组件', prop: 'dependency', color: '#0dcfcf' },
        { label: '漏洞影响的软件', prop: 'package', color: '#FF9126' },
        {
          label: '漏洞影响的传递性依赖',
          prop: 'transitiveDep',
          color: '#5A94F8',
        },
      ],
      colors: {
        vulnerability: '#FF0000',
        dependency: '#0dcfcf',
        package: '#FF9126',
        transitiveDep: '#5A94F8',
      },
      dataList: {},
      chartTableData: [],
      isEchart: true,
      checked: '',
      productName: '',
      vulId: '',
    };
  },
  computed: {
    ...mapGetters(['getProductName']),
  },
  watch: {},
  methods: {
    initEchart(vulId) {
      this.isEchart = true;
      this.chartData = {};
      this.getChartData(vulId);
    },
    getChartData(vulId: string) {
      SbomDataService.queryVulImpact(this.productName, vulId)
        .then((response: ResponseData) => {
          const { nodes, edges } = response.data;
          let vTotla = [];
          let dTotla = [];
          let pTotal = [];
          let tTotal = [];
          if (nodes.length) {
            vTotla = nodes.filter((node) => node.nodeType === 'vulnerability');
            dTotla = nodes.filter((node) => node.nodeType === 'dependency');
            pTotal = nodes.filter((node) => node.nodeType === 'package');
            tTotal = nodes.filter((node) => node.nodeType === 'transitiveDep');
            this.dataList = {
              vulnerability: vTotla,
              dependency: dTotla,
              package: pTotal,
              transitiveDep: tTotal,
            };
          }
          vTotla = this.formatNodes(vTotla, 0, true);
          dTotla = this.formatNodes(dTotla, 1, true);
          pTotal = this.formatNodes(pTotal, dTotla.length, true);
          tTotal = this.formatNodes(
            tTotal,
            dTotla.length + pTotal.length,
            false
          );
          this.chartData = {
            nodes: vTotla.concat(dTotla).concat(pTotal).concat(tTotal),
            edges,
          };
          this.checked = '';
          this.handleSelect('');
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: 'error',
            });
          }
          this.chartData = [];
        });
    },
    formatNodes(data, index, flag) {
      if (data.length) {
        const colors = this.colors;
        const count = 220;
        const yTotal = index * count;
        data = data.sort((a, b) => a.y - b.y);
        data.map((node, nodeIndex) => {
          node.color = colors[node.nodeType];
          node.x = node.x * 2.5;
          node.y = node.y + yTotal + (flag ? count * nodeIndex : 0);
        });
      }
      return data;
    },
    goDetailPath(elementId, nodeType) {
      if (elementId && nodeType && nodeType !== 'vulnerability') {
        let flag;
        let productName;
        if (this.getProductName) {
          flag =
            this.getProductName && this.getProductName.includes('openEuler');
          productName = this.getProductName;
        } else {
          flag = this.$route.params.flag || false;
          productName = this.$route.query.productName || '';
        }
        const { href } = this.$router.resolve({
          path: `/sbomPackageDetail/${elementId}/${flag}`,
          query: {
            productName: productName,
          },
        });
        window.open(href, '_blank');
      }
    },
    echartsClick(name, params) {
      const { nodeType, elementId } = params.value;
      this.goDetailPath(elementId, nodeType);
    },
    handleSelect(prop) {
      this.chartTableData = [];
      const dataList = this.dataList;
      if (prop) {
        this.chartTableData = dataList[prop];
      } else {
        const keys = Object.keys(dataList);
        keys.map((key) => {
          if (key !== 'vulnerability') {
            this.chartTableData = this.chartTableData.concat(dataList[key]);
          }
        });
      }
    },
  },
  mounted() {
    const { productName, vulId } = this.$route.query
    this.productName = productName ? productName.toString() : ''
    this.vulId = vulId ? vulId.toString() : ''
    this.initEchart(vulId)
  },
});
